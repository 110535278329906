import "./mercearia.css";
import biscoitoAguaSalTriunfo345g from "./mercearia-images/biscoitoAguaSalTriunfo345g.png";
import biscoitoNikitoRecheadoChocolatoMorango from "./mercearia-images/biscoitoNikitoRecheadoChocolatoMorango.png";
import biscoitoSalgadoSolSaltPlus380g from "./mercearia-images/biscoitoSalgadoSolSaltPlus380g.png";
import biscoitoTortuguitaRecheadoSabores120g from "./mercearia-images/biscoitoTortuguitaSabores120g.png";
import biscoitoPiraqueCookiesChocolateOriginal80g from "./mercearia-images/biscoitoPiraqueCookiesChocolateOriginal80g.png";
import biscoitoTortuguitaMaizenaLeite170g from "./mercearia-images/biscoitoTortuguitaMaizenaLeite170g.png";
import leiteCondensadoTriangulo395g from "./mercearia-images/leiteCondensadoTriangulo395g.png";
import cremeLeiteNestle200g from "./mercearia-images/cremeLeiteNestle200g.png";
import doceLeiteDonLeche420g from "./mercearia-images/doceLeiteDonLeche420g.png";
import chocolateVariadosTortuguitaArcor146g from "./mercearia-images/chocolateVariadosTortuguitaArcor146g.png";
import salgadinhosTakisSabores56g from "./mercearia-images/salgadinhosTakisSabores56g.png";
import RefrescoFriscoSabores18g from "./mercearia-images/RefrescoFriscoSabores18g.png";
import gelatinaSolSabores20g from "./mercearia-images/gelatinaSolSabores20g.png";
import cafeAltinopolisPremium500g from "./mercearia-images/cafeAltinopolisPremium500g.png";
import achocolatadoToddyOriginal750g from "./mercearia-images/achocolatadoToddyOriginal750g.png";
import achocolatadoChocolatto700g from "./mercearia-images/achocolatadoChocolatto700g.png";
import amacianteDownyConcentrado500ml from "./mercearia-images/amacianteDownyConcentrado500ml.png";
import sabaoemPoSurf5em1_800g from "./mercearia-images/sabaoemPoSurf5em1_800g.png";
import sabaoPoTixan800G from "./mercearia-images/sabaoPoTixan800G.png";
import detergenteYpe500ml from "./mercearia-images/detergenteYpe500ml.png";
import limpadorMultiUsoLimpol500ml from "./mercearia-images/limpadorMultiUsoLimpol500ml.png";
import aguaSanirariaTriex1Lt from "./mercearia-images/aguaSanirariaTriex1Lt.png";
import detergenteLimpol from "../../assets/detergente-limpol.png";
import sodaCausticaIndaia1Kg from "./mercearia-images/sodaCausticaIndaia1Kg.png";
import papelHigienicoSulleg from "./mercearia-images/papelHigienicoSulleg.png";
import sabaoEmPastaDipol500g from "./mercearia-images/sabaoEmPastaDipol500g.png";
import flotadorPingoMagico from "../../assets/flotador-pingo-magico.png";
import bolachaMarilan from "../../assets/bolacha-maizena-marilan-chocolate.png";
import bolachaTrakinas from "../../assets/bolacha-trakinas-morango.png";
import bolachaPassatempo from "../../assets/bolacha-passatempo.png";
import fraldaDescartavelEvolutionXG from "./mercearia-images/fraldaDescartavelEvolutionXG.png";
import absorventeIntimusGel8unid from "./mercearia-images/absorventeIntimusGel8unid.png";
import shampooElseve200ml from "./mercearia-images/shampooElseve200ml.png";
import condicionadorElseve200ml from "./mercearia-images/condicionadorElseve200ml.png";
import panteneCondicionador150ml from "./mercearia-images/panteneCondicionador150ml.png";
import listerineCoolMintEnxaguanteBucal500ml from "./mercearia-images/listerineCoolMintEnxaguanteBucal500ml.png";
import cremeDentalOralB70g from "./mercearia-images/cremeDentalOralB70g.png";
import aparelho_de_Barbear_Gillette_Prestobarba3 from "./mercearia-images/aparelho_de_Barbear_Gillette_Prestobarba3.png";
import arrozDuSafra5kgTipo1 from "./mercearia-images/arrozDuSafra5kgTipo1.png";
import maioneseSoyaCaseiraPote500Gr from "./mercearia-images/maioneseSoyaCaseiraPote500Gr.png";
import milhoVerdeemConservaueroLata170g from "./mercearia-images/milhoVerdeemConservaueroLata170g.png";
import macarraoSemoladoBasilarEspaguete400g from "./mercearia-images/macarraoSemoladoBasilarEspaguete400g.png";
import aveiaNestleFlocos from "../../assets/aveia-flocos-nestle.png";
import chaMateLeao from "../../assets/chaMatteLeaoSabores10g.png";
import queijoParmesãoReliquiaSitio50gRalado from "./mercearia-images/queijoParmesãoReliquiaSitio50gRalado.png";
import feijaoCariocaTipo1DuSafraFeijãoCarioca from "./mercearia-images/feijaoCariocaTipo1DuSafraFeijãoCarioca.png";
import vinagreCasteloAlcoolColorido750ml from "./mercearia-images/vinagreCasteloAlcoolColorido750ml.png";
import seletaLegumes from "../../assets/seletaLegumes.png";
import filtrocafepacaembu103 from "../../assets/filtro-cafe-pacaembu-103.png";
import ketchupHeinz from "../../assets/ketchupHeinz.png";
import arrozPreferencia5KG from "../../assets/arrozPreferencia.png";
import feijaoPatureba1kg from "../../assets/feijaoPatureba1kg.png";
import vinagreCristal from "../../assets/vinagreCristal.png";
import oleoAzeiteLisboa from "../../assets/oleoAzeiteLisboa.png";
import gilletePrestobarba from "../../assets/gilletePrestobarba.png";
import OldSpiceVip72h from "../../assets/spice-90g.png";
import vejaMultiuso from "../../assets/vejaMultiuso.png";
import lustraMoveisPoliflor from "../../assets/lustraMoveisPoliflor.png";
import papelHigienicoNeve from "../../assets/papelHigienicoNeve.png";
import papelHigienicoMili from "../../assets/papel-higienic-mili-60m.png";
import papelHigienicoNeve4rolos from "../../assets/papel_higienico_neve_4rolos.png";
import tintaParaCabelo from "../../assets/tinta-para-cabelo.png";
import sacoLixoPoliluz from "../../assets/sacoLixoPoliluz.png";
import urcaLavaRoupas from "../../assets/urcaLavaRoupas.png";
import papel_aluminio_parana from "../../assets/papel_aluminio_parana.png";
import nissin_lamem_miojo from "../../assets/nissin_lamem_miojo.png";
import manteigaReliquia from "../../assets/manteiga-reliquia.png";
import alcool_fortys_92 from "../../assets/alcool_Fortys.png";
import tempero_tempero_pronto_tchetto from "../../assets/tempero_tempero_pronto_tchetto.png";
import sabaoPoSurf5Em1 from "../../assets/sabao-em-po-surf-5em1.png";
import amacianteRoupasUrca from "../../assets/amaciante-urca-2l.png";
import desinfetanteUrca from "../../assets/desinfetante-urca.png";
import farofa_yoki from "../../assets/farofa_temperada_yoki.png";
import farofa_zei from "../../assets/farofa_temperada_zei.png";
import lar_doce_lar_500ml from "../../assets/lar_doce_lar_500ml.png";
import saco_de_lixo_verom from "../../assets/saco_de_lixo_verom.png";
import sorvetePimpinella from "../../assets/sorvete-pimpinella.png";
import sucoBrassukSabores from "../../assets/suco-brassuk.png";
import absorveteAlways from "../../assets/absorvente-always.png";
import sorveteOlhoDeSogra from "../../assets/sorvete-olho-sogra-2L.png";
import bolachaSequilhos from "../../assets/bolacha-sequilhos-leite.png";
import bolachaMaizenaTostines from "../../assets/bolachaMaizenaTostines.png";
import cafeDuSafra from "../../assets/cafeDuSafra.png";
import gilletePrestobarbaSeries from "../../assets/gillette-prestobarba-series.png";
import gilletteVenus from "../../assets/gillete-venus.png";
import absorventeAways16 from "../../assets/absorventeAways16.png";
import bomArFragancias from "../../assets/bom-ar-fragancias.png";
import fraldasDescartaveisBaby from "../../assets/fraldas-descartaveis-baby.png";
import toalhasUmedecidasBaby from "../../assets/toalhas-umedecidas.png";
import shampooCondicionadorJaborandi from "../../assets/shampoo-condicionador-jaborandi.png";
import shampooCondicionadorCamomila from "../../assets/shampoo-condicionador-camomila.png";
import macarraoTalharimCaseiro from "../../assets/macarraoTalharimCaseiro.png";
import queijoRaladoPalomino from "../../assets/queijoRaladoPalomino.png";
import molhoExtratoTomateVal from "../../assets/molhoExtratoTomateVal.png";
import ketchupTchetto from "../../assets/ketchupTchetto.png";
import maioneseHeinz from "../../assets/maioneseHeinz.png";
import petaGoiana from "../../assets/petaGoiana.png";

function Mercearia() {
  return (
    <div>
      <div className="merceariaTitulo titulo-setor" id="mercearia">
        MERCEARIA
      </div>
      <div className="merceariaContent">
        <div className="container-ofertas promocoes-container">
          <div className="promo-item">
            <img
              src={macarraoTalharimCaseiro}
              alt="Macarrão De Talharim Caseiro N3 500g"
            />
            <p>Macarrão De Talharim Caseiro N3 500g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>6,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={queijoRaladoPalomino} alt="Queijo Ralado Palomino 40g" />
            <p>Queijo Ralado Palomino 40g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>5,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={molhoExtratoTomateVal}
              alt="Molho Extrato de Tomate Val"
            />
            <p>Molho Extrato de Tomate Val</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>1,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={ketchupTchetto} alt="ketchup Tchetto 180g" />
            <p>ketchup Tchetto 180g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>4,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={maioneseHeinz} alt="Maionese Heinz 390g" />
            <p>Maionese Heinz 390g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>16,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={achocolatadoToddyOriginal750g}
              alt="Achocolatado Toddy Original 750g"
            />
            <p>Achocolatado Toddy Original 750g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>19,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={petaGoiana}
              alt="Pão de Queijo Peta Goiana Lut Minas 800g"
            />
            <p>Pão de Queijo Peta Goiana Lut Minas 800g</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>18,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={sorveteOlhoDeSogra}
              alt="Sorvete Pimpinella Nozes | Flocos"
            />
            <p>Sorvete Olho de Sogra 2L</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>22,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={bolachaSequilhos} alt="Bolacha Sequilhos Sabor Leite" />
            <p>Bolacha Sequilhos Sabor Leite</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>11,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={bolachaMaizenaTostines}
              alt="Bolacha Sequilhos Sabor Leite"
            />
            <p>Bolacha Maisena Tostines 200G</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>4,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cafeDuSafra} alt="Café Du Safra" />
            <p>Café Du Safra 500G</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>24,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img
              src={gilletePrestobarbaSeries}
              alt="Aparelho De Barbear Gillette Prestobarba 3 "
            />
            <p>Aparelho De Barbear Gillette Prestobarba 3 </p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>6,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={gilletteVenus}
              alt="Gillette Venus Suave Aparelho de Depilação "
            />
            <p>Gillette Venus Suave Aparelho de Depilação </p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>6,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={absorventeAways16}
              alt="Absorvente Always Noturno Suave G com Abas com 16 unidades"
            />
            <p>Absorvente Always Suave G com Abas com 16 unidades</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>6,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={sabaoEmPastaDipol500g} alt="sabaoEmPastaDipol500g" />
            <p> Sabão em Pasta Dipol 500g</p>
            <br />
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>5,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={desinfetanteUrca} alt="Desinfetante Urca 500ml" />
            <p>Desinfetante Urca 500ml</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={vejaMultiuso} alt="Limpador Multiuso Veja 500ml" />
            <p>Limpador Multiuso Veja 500ml</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>5,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={bomArFragancias}
              alt="Bom Ar Spray Air Wick Original Aromatizante"
            />
            <p>Bom Ar Spray Air Wick Original Aromatizante 360ml</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>16,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={fraldasDescartaveisBaby}
              alt="Fraldas descartáveis Baby 44unid."
            />
            <p>Fraldas descartáveis Baby 44unid.</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>39,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={toalhasUmedecidasBaby}
              alt="Toalhas Umedecidas Baby 120unid."
            />
            <p>Toalhas Umedecidas Baby 120unid.</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>9,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={shampooCondicionadorJaborandi}
              alt="Kit Econômico Shampoo e Condicionador Jaborandi"
            />
            <p>Kit Econômico Shampoo e Condicionador Jaborandi</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>12,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img
              src={shampooCondicionadorCamomila}
              alt="Kit Econômico Shampoo e Condicionador Camomila"
            />
            <p>Kit Econômico Shampoo e Condicionador Camomila</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>12,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mercearia;

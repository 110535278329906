import React, { useEffect, useRef, useState } from "react";
import "./promocoes.css";
import "../global.css";
import logoPromocao from "./logo-promocao/black-friday-saltarelli.png";
import energeticoMonsterGreen from "./images-promocoes/energeticoMonsterGreen.png";
import amstelFardoLata350ml from "../../assets/amstel-lata.png";
import cervejaBrahmaLata from "../../assets/brahma-350ml.png";
import crystalLataFardo from "../../assets/crystal-lata.png";
import paoFrances from "./images-promocoes/pao.png";
import paoDeAlho from "../../assets/pao_de_alho_mencucini.png";
import lasanha_seara from "../../assets/lasanha_seara.png";
import paodequeijo from "./images-promocoes/paodequeijo.png";
import cocaColaZero from "./images-promocoes/cocaColaZero.png";
import sucoDelValleSabores from "./images-promocoes/sucoDelValleSabores.png";
import cuidadosComCabelo from "./images-promocoes/cuidadosComCabelo.png";
import churrasco_ContraFile_Frango from "./images-promocoes/churrasco_CarneBovina_Frango.png";
import tulipa_Temperada_Fundo_Preto from "./images-promocoes/tulipa_Temperada_FundoPreto.png";
import coxinha_da_asa from "../../assets/coxinha-da-asa.png";
import cerveja_sabores from "./images-promocoes/cervejas_sabores.png";
import pontaAlcatraAngus from "./images-promocoes/pontaAlcatraAngus.png";
import carneMoida from "../../assets/carne-moida.png";
import paleta_bovina from "./images-promocoes/paleta_bovina.png";
import cervejaLataImperio from "../../assets/imperio-lata.png";
import whatssappIcon from "./images-promocoes/whatssapp.svg";
import cervejaPuroMalte from "../../assets/puro-malte-lata.png";
import kitChurrasco from "./images-promocoes/kit-churrasco.png";
import videoFilao from "../../assets/videoFilao.mp4";
import vinhoParatudo from "../../assets/vinhoParatudo.png";
import vinhoBordo from "../../assets/vinhoTintoBordo.png";
import pingaPirassununga from "../../assets/pingaPirassununga.png";
import pingaMoenda from "../../assets/pingaMoenda.png";
import guaranaMaca from "../../assets/guaranaMaca.png";
import paoFrancesCapa from "../../assets/PaoFrancesCapa.png"; // importando a imagem da capa
import capavideoBalcaoAcougue from "../../assets/capa_balcao_acougue_mascote.png";
import costelaBafo from "../../assets/costela.png";
import costelinha_Suina from "../../assets/costelinha_Suina.png";
import pontaDePeito from "../../assets/ponta_de_Peito.png";
import budweiserFardolata350ml from "../../assets/budweiser-lata.png";
import cerveja_Spaten_lata from "../../assets/cerveja_Spaten_lata.png";
import cervejaAntartictaBoa from "../../assets/cerveja-antarctica-boa-350ml.png";
import carvao_pitoko_3KG from "../../assets/carvao-pitoko-3kg.png";
import carvao_pitoko_8KG from "../../assets/carvao-pitoko-8kg.png";
import pernilSemOsso from "../../assets/pernil-sem-osso.png";
import panceta from "../../assets/panceta.png";
import linguica_churrasco from "../../assets/linguica_churrasco.png";
import linguicaCaipira from "../../assets/linguica_caipira.png";
import linguicaToscana from "../../assets/linguica_Toscana.png";
import fileFrango from "../../assets/filePeitoFrango.png";
import frango_Passarinho from "../../assets/frango_Passarinho.png";
import video_acougue from "../../assets/video_acougue.mp4";
import VideoPlayer from "../Video_Player";
import cervejaHeinekenLata from "../../assets/heiniken-lata-350ml.png";
import skollata from "../../assets/skol-lata.png";
import cervejaSubzeroLata from "../../assets/cerveja-subzero-350ml.png";
import figadoBovino from "../../assets/figado_Bovino.png";
import guaranaFabri from "../../assets/guaranaFabri2l.png";
import paoAlhoLutMinas from "../../assets/pao-alho-lut-minas.png";

import { FaPlay, FaPause } from "react-icons/fa";

function Promocoes() {
  return (
    <div className="container-ofertas" id="promocoes">
      <div className="tag-promocoes-container">
        <a
          href="https://chat.whatsapp.com/GdYrxmhf7xq3VcdIxXN1Vl"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-fixo"
          id="grupoOfertas"
        >
          <img
            src={whatssappIcon}
            alt="WhatsApp"
            id="whatsappIcon"
            style={{ width: "55px", height: "auto" }}
          />

          <div className="tooltip" id="whatsappTooltip">
            FAÇA PARTE DO GRUPO DO SALTARELLI
          </div>
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="container-titulo-interativo">
          <div className="titulo-interativo linha-dois">
            2025 É NO SALTARELLI O LUGAR CERTO
          </div>
          <br />

          {/* <VideoPlayer /> */}
        </div>

        <div className="promocoes-container">
          <div className="promo-item">
            <img src={pontaDePeito} alt="Ponta de Peito" />
            <p>Ponta de Peito</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>34,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item" id="acougue">
            <img src={linguicaToscana} alt="Linguiça Toscana" />
            <p>Linguiça Toscana</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>19,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={frango_Passarinho} alt="Frango Passarinho" />
            <p>Frango Passarinho</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>12,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={carneMoida} alt="Carne Moída" />
            <p>Carne Moída</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>34,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={figadoBovino} alt="Fígado Bovino" />
            <p> Fígado Bovino </p>
            <br />
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>12,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={tulipa_Temperada_Fundo_Preto} alt="tulipa Temperada" />
            <p> Tulipa Temperada</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>19,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={coxinha_da_asa} alt="Coxinha da Asa de Frango" />
            <p> Coxinha da Asa de Frango</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>16,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={costelinha_Suina} alt="costelinha_Suina" />
            <p> Costelinha Suina </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>24,99
                <span className="valor-unidade">Kg.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={skollata} alt="cerveja Lata Heineken" />
            <p> Skol lata 350ml </p>
            <p className="embalagem-info">Fardo c/18 R$ 59,99</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,33
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cervejaAntartictaBoa} alt="Antarctica BOA" />
            <p> Antarctica BOA Lata 350ml</p>
            <p className="embalagem-info">Fardo c/18 R$ 55,00</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,05
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cervejaBrahmaLata} alt="Brahma lata 350ml" />
            <p> Brahma lata 350ml </p>
            <p className="embalagem-info">Fardo c/18 R$ 59,99</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,33
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={cervejaPuroMalte} alt="cerveja Puro Malte" />
            <p> Puro Malte 3.0 lata 350ml </p>
            <p className="embalagem-info">Fardo c/12 R$ 28,50</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,37
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={crystalLataFardo} alt="cristal Lata Fardo" />
            <p> Crystal lata 350ml</p>
            <p className="embalagem-info">Fardo c/12 R$ 28,50</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>2,37
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>
          <div className="promo-item">
            <img src={cervejaLataImperio} alt="Império Fardo Lata 350ml" />
            <p> Império lata 350ml </p>
            <p className="embalagem-info">Fardo c/15 R$ 45,75</p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>3,05
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={paoAlhoLutMinas} alt="Pão de Alho Lut Minas" />
            <p>Pão de Alho Lut Minas</p>
            <div className="title-oferta-medio">
              <p className="embalagem-info"></p>
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>8,99
                <span className="valor-unidade">Unid.</span>
              </h4>
            </div>
          </div>

          <div className="promo-item">
            <img src={guaranaFabri} alt="Guaraná Fabri 2L" />
            <p> Guaraná Fabri 2L </p>
            <div className="title-oferta-medio">
              <h4 className="valor-item-grande">
                <span className="cifrao">R$</span>5,99
                <span className="valor-unidade">unid.</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Promocoes;

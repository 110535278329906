import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Logo from "./components/Logo";
import Mercearia from "./components/Mercearia/index.";
import Hortifruti from "./components/Hortifruti";
import Acougue from "./components/Acougue";
import Padaria from "./components/Padaria";
import Footer from "./components/Footer";
import Promocoes from "./components/Promocoes";

// import BlocoNotas from "./blocoNotas";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {" "}
      {}
      <Logo />
      <Promocoes />
      {/* <Acougue /> */}
      <Padaria />
      <Mercearia />
      <Hortifruti />
      {/* <BlocoNotas /> */}
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

import "./hortifruti.css";
import "../global.css";
import logoHortfrutti from "./hortifruti/logo-hortifruti.png";
import tomate from "../../assets/tomate.png";
import cebola from "../../assets/cebola.png";
import limao from "../../assets/limao.png";
import batata from "../../assets/batata.png";
import cabotcha from "../../assets/caboctha.png";
import beterraba from "../../assets/beterraba.png";
import mamaoPapaya from "../../assets/mamao-papaya.png";
import goiaba from "../../assets/goiaba.png";
import cenoura from "../../assets/cenoura.png";

function Hortifruti() {
  return (
    <div id="hortifruti">
      <div className="hortifruti-content">
        {/* Título chamativo */}
        <h1 className="hortifruti-title">
          Venha conhecer nosso Hortifruti, os melhores produtos para sua saúde!
        </h1>

        {/* Logo centralizada */}
        <div className="tlogo-hortfruti">
          <img src={logoHortfrutti} alt="Hortifruti" />
        </div>
      </div>
      <br />

      <div className=" promocoes-container">
        <div className="promo-item">
          <img src={cabotcha} alt="Cabotcha" />
          <p>Cabotcha</p>
          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>3,99
              <span className="valor-unidade">KG.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={cenoura} alt="Cenoura" />
          <p>Cenoura</p>
          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>3,99
              <span className="valor-unidade">KG.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={batata} alt="Batata Extra" />
          <p>Batata Extra</p>
          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>4,99
              <span className="valor-unidade">KG.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={cebola} alt="Cebola Extra" />
          <p>Cebola Extra</p>
          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>3,99
              <span className="valor-unidade">KG.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={beterraba} alt="Beterraba" />
          <p>Beterraba</p>
          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>3,99
              <span className="valor-unidade">KG.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={limao} alt="Limão " />
          <p>Limão</p>
          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>3,99
              <span className="valor-unidade">KG.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={goiaba} alt="Goiaba " />
          <p>Goiaba</p>
          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>3,99
              <span className="valor-unidade">KG.</span>
            </h4>
          </div>
        </div>

        <div className="promo-item">
          <img src={mamaoPapaya} alt="" />
          <p>Mamão Papaia</p>
          <div className="title-oferta-medio">
            <p className="embalagem-info"></p>
            <h4 className="valor-item-grande">
              <span className="cifrao">R$</span>5,99
              <span className="valor-unidade">Unid.</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hortifruti;
